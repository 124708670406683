<template>
    <section class="content">
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>Nama Siswa</th>
                    <th>Asal Sekolah</th>
                    <th>Kelas</th>
                    <th>No HP</th>
                    <th>Tujuan Kedinasan</th>
                    <th>Follow UP I</th>
                    <th>Follow UP II</th>
                    <th>Follow UP III</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Audience</label>
                                <input type="text" name=""  v-model="form.nama" class="form-control">
                                <!-- <select v-model="form.siswa_id" class="form-control">
                                    <option v-for="siswa in optSiswa" v-bind:value="siswa.id">{{ siswa.label }}</option>
                                </select> -->
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Asal Sekolah</label>
                                <input type="text" name="asal_sekolah" v-model="form.asal_sekolah" class="form-control">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Kelas</label>
                                <input type="text" name="kelas" v-model="form.kelas" class="form-control">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">No.HP</label>
                                <input type="text" name="no_hp" v-model="form.no_hp" class="form-control">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tujuan Kedinasan</label>
                                <select v-model="form.tujuan_kedinasan_id" class="form-control">
                                    <option v-for="data in optSekolahDinas" v-bind:value="data.id">{{ data.label }}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Follow Up 1</label>
                                <textarea class="form-control" v-model="form.followup1"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Follow Up 2</label>
                                <textarea class="form-control" v-model="form.followup2"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Follow Up 3</label>
                                <textarea class="form-control" v-model="form.followup3"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Status</label>
                                <select v-model="form.status" class="form-control">
                                    <option v-for="item in optStatus" v-bind:value="item.id">{{ item.label }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from 'moment';
import datepicker from '@/components/Datepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css'

export default {
    name: 'RekapPRAudience',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            optSiswa: [],
            optSekolahDinas: [],
            formTitle: 'Tambah',
            form: {
                id: '',
                siswa_id: '',
                tujuan_kedinasan: '',
                followup1: '',
                followup2: '',
                followup3:'',
                status:'',
                nama: '',
                asal_sekolah: '',
                no_hp: '',
                kelas: '',
            },
            form_data: '',
            optStatus: [
                {id: '0', label: 'Ragu'},
                {id: '1', label: 'Daftar'},
                {id: '2', label: 'Tidak daftar'}
            ],
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;

        authFetch('/pr/rekap_pr_audience/siswa')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optSiswa = js.data;
            })

        authFetch('/pr/rekap_pr_audience/sekolah_dinas')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optSekolahDinas = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        submitForm: function(ev) {
            var self = this;
            const e = this.$refs;

            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/pr/rekap_pr_audience';
            if (this.method == 'PUT') urlSubmit = '/pr/rekap_pr_audience/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        Swal.fire({
                            icon: 'info',
                            title: 'Informasi',
                            text: 'Data tidak tersimpan!'
                        })

                        return;
                    }
                    this.table.api().ajax.reload();
                    Swal.fire({
                        icon: 'success',
                        title: 'Informasi',
                        text: 'Data tersimpan!'
                    })
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        },
        uploadFoto : function (file) {
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
            });
            form_data.append('subdir', '/laporan-dinas-luar/');
            form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
        },
        doUpload: function() {
            return authFetch('/pr/rekap_pr_audience/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                  'Content-Type': null,
                }
            })
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "List PR Audience",
            "roles": this.$route.params.roles,
            "ajax": "/pr/rekap_pr_audience",
            scrollX: true,
            "columns": [
                { "data": "nama" },
                { "data": "asal_sekolah" },
                { "data": "kelas" },
                { "data": "no_hp" },
                { "data": "singkatan" },
                { "data": "followup1" },
                { "data": "followup2" },
                { "data": "followup3" },
                { "data": "status", sortable: false },
            ],
            filterBy: [0, 1, 2, 3, 4, 5, 6, 7],
            "rowCallback": function(row, data) {
                if (data.status == '0') {
                    $('td:eq(8)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-primary" style="color:white">Ragu</a>');
                } else if (data.status == '1') {
                    $('td:eq(8)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-success" style="color:white">Daftar</a>');
                } else {
                    $('td:eq(8)', row).html('<a style="padding:0.5em;font-size:94%;color:white" class="badge badge-danger" style="color:white">Tidak Daftar</a>');
                }
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah PR Audience';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    
                    var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
                    namahari = namahari.split(" ");
                    var tgl = new Date(evt.data.tanggal_mulai);
                    var hari = tgl.getDay();

                    self.form.tanggal = evt.data.start;
                    self.form.followup = evt.data.followup;
                    self.form.hari = namahari[hari];
                    self.form.keterangan = evt.data.description;
                    self.form.tujuan = evt.data.tujuan_kedinasan;
                    self.form.agenda = evt.data.title;
                    self.form.bukti = evt.data.bukti;
                    self.form.beritaacara = evt.data.note_hasil;
                    self.form.nama = evt.data.nama;
                    self.form.asal_sekolah = evt.data.asal_sekolah;
                    self.form.no_hp = evt.data.no_hp;
                    self.form.kelas = evt.data.kelas;
                    
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit PR Audience';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/pr/rekap_pr_audience/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
        });

    }
}
</script>